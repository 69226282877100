import React from "react";
import "./Wedo.css";

const Wedo = () => {
  return (
    <div className="section-wedo" id="about">
      <div className="wedo-cont-first">
        <h4 className="wedo-text-head">
          About <span className="span-wedo">Us</span>
        </h4>
        <div className="wedo-image-cont">
          <img src="images/About4.png" className="about-img" alt="custom" />
        </div>
      </div>
      <div className="wedo-text-cont">
        <h3 className="wedo-text-sub-head">
          Get to know about <span className="span-company">our company</span>
        </h3>
        <p className="wedo-text-sub-pass">
          At Aristeia, we believe that every business is unique, with its own
          set of challenges and opportunities. That's why we're dedicated to
          helping our clients customize their business processes to suit their
          specific needs. With a strong foundation built on honesty, integrity,
          and teamwork, our team of experts works collaboratively with clients
          to deliver tailored solutions that drive growth, reduce costs , and
          optimize revenue. Our mission is to empower businesses to reach their
          full potential, and our vision is to be the trusted partner of choice
          for companies seeking to transform their operations. We're passionate
          about what we do, and we're committed to delivering exceptional
          results for our clients. Let us help you unlock your business's
          potential.
        </p>

        <div>
          <h3 className="wedo-text-sub-head">
            Our <span className="span-company">Mission</span>
          </h3>
          <p className="wedo-text-sub-pass">
            Our mission is to deliver tailored business solutions with honesty
            and integrity, empowering our clients to optimize revenue and reduce
            costs through collaborative and innovative process customizations.
          </p>
        </div>

        <div>
          <h3 className="wedo-text-sub-head">
            Our <span className="span-company">Vision</span>
          </h3>
          <p className="wedo-text-sub-pass">
            Our vision is to be the trusted partner of choice for businesses
            seeking to transform their operations, driving growth and
            profitability through customized solutions that unlock their full
            potential.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Wedo;
